import { createFeatureSelector, createSelector } from '@ngrx/store';

import { QuoteRequestWidget } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const selectState = createFeatureSelector<State>('quoteRequestWidgets');

export const selectAllItems =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectById = (id: number) =>
  createSelector(
    selectAllItems,
    (items: QuoteRequestWidget[]) => items && items.find((p) => +p.id === +id),
  );

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);
