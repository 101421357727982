import { createAction, props } from '@ngrx/store';

import { QuoteRequestWidget, QuoteRequestWidgetRequest } from '../../models';

export const loadRequest = createAction(
  '[Quote Request Widgets] Load Request',
  props<{
    properties: number[];
  }>(),
);

export const loadSuccess = createAction(
  '[Quote Request Widgets] Load Success',
  props<{ items: QuoteRequestWidget[] }>(),
);

export const loadFailure = createAction(
  '[Quote Request Widgets] Load Failure',
  props<{ error: any }>(),
);

export const loadByIdRequest = createAction(
  '[Quote Request Widgets] Load By Id Request',
  props<{ itemId: number }>(),
);

export const loadByIdSuccess = createAction(
  '[Quote Request Widgets] Load By Id Success',
  props<{ item: QuoteRequestWidget }>(),
);

export const loadByIdFailure = createAction(
  '[Quote Request Widgets] Load By Id Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Quote Request Widgets] Delete Request',
  props<{ itemId: number }>(),
);

export const deleteSuccess = createAction(
  '[Quote Request Widgets] Delete Success',
  props<{ itemId: number }>(),
);

export const deleteFailure = createAction(
  '[Quote Request Widgets] Load Failure',
  props<{ error: any }>(),
);

export const createRequest = createAction(
  '[Quote Request Widgets] Create Request',
  props<{ request: QuoteRequestWidgetRequest }>(),
);

export const createSuccess = createAction(
  '[Quote Request Widgets] Create Success',
  props<{ item: QuoteRequestWidget }>(),
);

export const createFailure = createAction(
  '[Quote Request Widgets] Create Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Quote Request Widgets] Update Request',
  props<{ request: QuoteRequestWidgetRequest; id: number }>(),
);

export const updateSuccess = createAction(
  '[Quote Request Widgets] Update Success',
  props<{ item: QuoteRequestWidget }>(),
);

export const updateFailure = createAction(
  '[Quote Request Widgets] Update Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Quote Request Widgets] Reset State');
