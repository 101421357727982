import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { QuoteRequestWidgetsService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class QuoteRequestWidgetsStoreEffects {
  constructor(
    private dataService: QuoteRequestWidgetsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private notifications: NotificationService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ properties }) =>
        this.dataService.load(properties).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadSuccess({
              items: (response.data || []).map((quoteRequestwidget) => ({
                ...quoteRequestwidget,
                booking_widget_ids: (
                  quoteRequestwidget.booking_widget_ids || []
                ).map((bookingWidget) => ({
                  ...bookingWidget,
                  complete_url: `https://${bookingWidget.dns}.beddy.${
                    environment.production ? 'io' : 'dev'
                  }`,
                })),
              })),
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadByIdRequest),
      switchMap(({ itemId }) =>
        this.dataService.loadById(itemId).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadByIdSuccess({
              item: response.data[0],
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadByIdFailure(error));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ itemId }) =>
        this.dataService.delete(itemId).pipe(
          map(() => {
            this.notifications.deleteSuccess('quote_request_widget');
            return fromActions.deleteSuccess({
              itemId,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure(error));
          }),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createRequest),
      switchMap(({ request }) =>
        this.dataService.create(request).pipe(
          map((response: IResponseSuccess) => {
            this.notifications.createSuccess('quote_request_widget');
            this.router.navigate([
              '/configuration/booking-widgets-configurator/quote-request-widgets',
            ]);

            return fromActions.createSuccess({
              item: response.data[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ request, id }) =>
        this.dataService.update(request, id).pipe(
          map((response: IResponseSuccess) => {
            this.notifications.updateSuccess('quote_request_widget');
            this.router.navigate([
              '/configuration/booking-widgets-configurator/quote-request-widgets',
            ]);
            return fromActions.updateSuccess({
              item: response.data[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateFailure(error));
          }),
        ),
      ),
    ),
  );
}
