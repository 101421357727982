import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { QuoteRequestWidget } from '../../models';

export const featureAdapter: EntityAdapter<QuoteRequestWidget> =
  createEntityAdapter<QuoteRequestWidget>({
    selectId: (model) => model.id,
  });

export interface State extends EntityState<QuoteRequestWidget> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
});
